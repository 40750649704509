import React, { lazy, Suspense } from 'react'
import { HashRouter as Router, Routes, Route } from 'react-router-dom'
import { Spin } from 'antd'
import { connect } from 'react-redux'

// 懒加载 防止页面闪烁 加载完样式才显示 https://blog.csdn.net/qq_41632427/article/details/126316701
// v6跳转页面传参 https://segmentfault.com/a/1190000042309247
const HomeIndex = lazy(() => import('../views/HomeIndex'))
const BillInfo = lazy(() => import('../views/BillInfo'))
const Label = lazy(() => import('../views/Label'))
const ErrorLabel = lazy(() => import('../views/ErrorLabel'))
const CheckLine = lazy(() => import('../views/CheckLine'))
const lazyload = (children) => {
  return <Suspense>
    {children}
  </Suspense>
}

function IndexVouter(props) {

  return (
    <Router>
      <Spin spinning={props.isLoading} tip="Loading" size="large">
        <Routes>
          <Route path="/" element={lazyload(<HomeIndex />)} />
          <Route path={'/info'} element={lazyload(<BillInfo />)} />
          <Route path={'/label'} element={lazyload(<Label />)} />
          <Route path={'/error_label'} element={lazyload(<ErrorLabel />)} />
          <Route path={'/check'} element={lazyload(<CheckLine />)} />
        </Routes>
      </Spin>
    </Router>
  )
}
const mapStateToProps = ({ LoadingReducer: { isLoading } }) => ({
  isLoading
})
export default connect(mapStateToProps)(IndexVouter)
