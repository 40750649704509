
import MRouter from './vouter/IndexVouter'
import { Provider } from 'react-redux';
import store from './redux/store'
import './app.css'

function App() {
  return (
    <Provider store={store}>
      <div className="App">
        <MRouter />
      </div>
    </Provider>

  );
}

export default App;

